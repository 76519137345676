import React, { CSSProperties, FC, ReactNode } from 'react';
import { css } from '@emotion/css';
import clsx from 'clsx';

interface DescriptionProps extends CSSProperties {
  children: ReactNode;
}

const Description: FC<DescriptionProps> = (props) => {
  const { children, ...sx } = props;
  return <h2 className={clsx(styles.root, css({ ...sx }))}>{children}</h2>;
};

const styles = {
  root: css`
    font-weight: 400;
    font-size: 18px;
    line-height: 150%;
    color: #979797;
  `,
};

export default Description;
