import React, {CSSProperties, FC, useEffect, useRef} from "react";
import Card from 'evcharging/components/Card';
import Box from 'evcharging/components/Box';
import Title from 'evcharging/components/Title';
import Description from 'evcharging/components/Description';
import Desktop from 'evcharging/components/Desktop';
import Spacer from 'evcharging/components/Spacer';
import {
  CONTACT_US_ID,
  EV_CALCULATOR_URL,
  EV_CHARGER_URL,
  EV_REBATES_URL,
  HOME_URL,
} from 'evcharging/app/seo/urls';
import Link from 'evcharging/components/Link';
import {useRouter} from 'next/router';
import useI18n from 'evcharging/hooks/useI18n';
import useTheme from 'evcharging/hooks/useTheme';
import {css} from '@emotion/css';
import RequestConsultationForm from "evcharging/containers/layout/RequestConsultationForm";

interface RequestConsultationCardProps extends CSSProperties {
  withBorder?: boolean;
  isRebatesForm?: boolean;
}

const getDescription = (pathname: string, t: (id: string) => string) => {
  switch (pathname) {
    case '/ev-charger':
      return (
        <>
          {t('charger.lead.intro1')}{' '}
          <Link href={EV_CALCULATOR_URL}>
            <u>{t('charger.lead.intro2')}</u>
          </Link>{' '}
          {t('charger.lead.intro3')}
        </>
      );
    case '/ev-rebates':
      return (
        <>
          {t('rebates.lead.intro1')}{' '}
          <Link href={HOME_URL}>
            <u>{t('rebates.lead.intro2')}</u>
          </Link>{' '}
          {t('rebates.lead.intro3')}{' '}
          <Link href={EV_CHARGER_URL}>
            <u>{t('rebates.lead.intro4')}</u>
          </Link>{' '}
          {t('rebates.lead.intro5')}{' '}
          <Link href={EV_CALCULATOR_URL}>
            <u>{t('rebates.lead.intro6')}</u>
          </Link>
        </>
      );
    case '/ev-calculator':
      return (
        <>
          {t('calculator.lead.intro1')}{' '}
          <Link href={HOME_URL}>
            <u>{t('calculator.lead.intro2')}</u>
          </Link>{' '}
          {t('calculator.lead.intro3')}{' '}
          <Link href={EV_REBATES_URL}>
            <u>{t('calculator.lead.intro4')}</u>
          </Link>{' '}
          {t('calculator.lead.intro5')}
        </>
      );
    case '/evcharging/rebates-for-our-ev-partners':
      return (
        <>
          {'Fill in our contact form, and we’ll get back to you within 24 hours to discuss how Energy5 can help with your rebates!'}{' '}
        </>
      );
    default:
      return (
        <>
          {t('form.lead.intro1')}{' '}
          <Link href={EV_CHARGER_URL}>
            <u>{t('form.lead.intro2')}</u>
          </Link>{' '}
          {t('form.lead.intro3')}
        </>
      );
  }
};

const RequestConsultationCard: FC<RequestConsultationCardProps> = (props) => {
  const {withBorder, isRebatesForm = false} = props;
  const {pathname} = useRouter();
  const {t} = useI18n();
  const theme = useTheme();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      while (ref.current.firstChild) {
        ref.current.removeChild(ref.current.firstChild);
      }
      const script = document.createElement('script');
      script.src = '/bitrix-lead-form.js';
      script.setAttribute('defer', '');
      script.setAttribute('data-b24-form', 'inline/9/eby1fq');
      script.setAttribute('data-skip-moving', 'true');
      ref.current?.append(script);
    }
  }, []);

  return (
    <Card maxWidth={810} withBorder={withBorder} backgroundColor={'#f6f8f9'}>
      <Box>
        <Title variant={'p'} fontSize={24} id={CONTACT_US_ID}>
          {t('form.lead.title')}
        </Title>
        <Spacer value={12} />
        <Description fontSize={16}>
          <Box
            maxWidth={600}
            className={css({
              [theme.breakpoints.mobile.down]: {
                margin: '0 auto',
              },
            })}
          >
            {getDescription(pathname, t)}
          </Box>
        </Description>
      </Box>
      <Spacer value={20} mobile={12} />
      <Desktop>
        <hr style={{border: ' 1px solid #EBEBEB'}}/>
        <Spacer value={24}/>
      </Desktop>
      <RequestConsultationForm isRebatesForm={isRebatesForm}/>
      {/*<div ref={ref} />*/}
    </Card>
  );
};

export default RequestConsultationCard;
