import { isEmail, isRequired } from 'evcharging/app/validation/validators';
import {REBATES_FOR_OUR_PARTNERS} from 'evcharging/app/server/endpoints';
import { httpClient } from 'evcharging/app/client/index';

const validators = {
  name: [isRequired],
  email: [isRequired, isEmail],
};

const sendRebatesForOurPartners = (payload: Record<string, string>) =>
  httpClient()
    .setBody(payload)
    .setValidators(validators)
    .post(REBATES_FOR_OUR_PARTNERS);

export default sendRebatesForOurPartners;
