import React, { FC, ReactNode } from "react";
import Metadata, { MetadataProps } from "evcharging/containers/layout/Metadata";
import BaselineCss from "evcharging/components/BaselineCss";
import { ThemeProvider } from "@emotion/react";
import lightTheme from "evcharging/app/themes/lightTheme";

interface PageProps extends MetadataProps {
  children: ReactNode;
}

const Page: FC<PageProps> = (props) => {
  const { children, ...metadata } = props;

  return (
    <ThemeProvider theme={lightTheme}>
      <BaselineCss />
      <Metadata {...metadata} />
      {children}
    </ThemeProvider>
  );
};

export default Page;
