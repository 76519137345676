import Section from 'evcharging/containers/layout/Section';
import RequestConsultationCard from 'evcharging/containers/layout/RequestConsultationCard';
import React from 'react';

const RequestConsultation = ({isRebatesForm = false}) => {
  return (
    <Section>
      <RequestConsultationCard withBorder isRebatesForm={isRebatesForm} />
    </Section>
  );
};

export default RequestConsultation;
