import React, { FC, ReactNode } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import energy5Theme from 'config/themes/energy5Theme';

interface E5AdapterProps {
  children: ReactNode;
}

const E5Adapter: FC<E5AdapterProps> = ({ children }) => {
  return (
    <ThemeProvider theme={energy5Theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default E5Adapter;
