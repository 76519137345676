import React, { CSSProperties, FC, ReactNode } from 'react';
import { Theme } from 'evcharging/app/themes';
import { css } from '@emotion/css';
import useTheme from 'evcharging/hooks/useTheme';
import clsx from 'clsx';

interface CardProps extends CSSProperties {
  children: ReactNode;
  withBorder?: boolean;
}

const Card: FC<CardProps> = (props) => {
  const { children, withBorder, ...sx } = props;
  const theme = useTheme();
  const styles = createStyles(theme, { withBorder });

  return <div className={clsx(styles.root, css({ ...sx }))}>{children}</div>;
};

const createStyles = (theme: Theme, { withBorder = false }: Partial<CardProps>) => ({
  root: css`
    width: 100%;
    margin: 0 auto;
    padding: 36px 50px;
    background: ${theme.colors.background.light};
    box-shadow: 0 4px 53px rgba(102, 102, 102, 0.05);
    border-radius: 16px;
    ${withBorder && 'border: 1px solid #e6e6e6;'}

    ${theme.breakpoints.mobile.down} {
      padding: 36px 15px;
      border-radius: 0;
      border: none;
      box-shadow: none;
    }
  `,
});

export default Card;
